import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import moment from 'moment'
import theme from '../helpers/theme'
import Layout from '../components/layout'
import LinkOut from '../components/link-out'
import Markdown from '../components/markdown'
import EventThumbnail from '../components/event-thumbnail'
import IMG from '../components/image'
import SEO from '../components/seo'

const EventPage = (props) => {

  const now = parseInt(moment().format('YYYYMMDD'))

  const settings = props.data.settings.edges[0].node

  const {
    title,
    dateLong,
    coverImage,
    navImage,
    bodyCopy,
    address,
    details,
    linkText,
    linkUrl
  } = props.data.contentfulEvent;

  // MICROCOPY
  const {
    viewAll,
		upcomingEvents
  } = settings

  return (
    <Layout image={navImage || settings.navImageEvents} theme={theme.white} size="small" settings={settings} location={props.location}>
      <SEO title={title} />

      <section className="innerx2">
        <hgroup className="masthead span-8 guttersx2">
          <h2 className="adjust mbx2">{dateLong}</h2>
          <h1 className="adjust">
            {title}
          </h1>
        </hgroup>
        <div className="span-8 innerx2 rauto">
          <div className="event-poster guttersx2">
            <picture>
              <IMG {...coverImage} />
            </picture>
          </div>

          <div className="event-details gutters-weak">
            {!linkUrl || (
              <div className="markdown span-4 gutters inner">
                <LinkOut href={linkUrl} className="button h4">{linkText}</LinkOut>
              </div>
            )}
            <Markdown field={address} className="span-4 has-links gutters inner" />
            <Markdown field={details} className="span-4 gutters inner" />
          </div>
        </div>
      </section>

      <section className="padx2 mbx2">
        <Markdown field={bodyCopy} className="span-8 md" />
      </section>
			
			{props.data.relatedEvents && (
				<section className="border-top">

					<h2 className="adjust guttersx2 innerx2">{upcomingEvents}</h2>

					<div className="border-top">
						{props.data.relatedEvents.edges.map(({node}, i) => {
              const end = node.end || node.start
              const date = parseInt(moment(end).format('YYYYMMDD'))
              return now <= date ? (
                <EventThumbnail {...node} theme={theme.white} key={`${node.slug}-event-link`} />
              ) : null
            })}
					</div>

				</section>
			)}

			<div className="guttersx2 inner">
				<Link to="/events" className="h2 link">{viewAll}</Link>
			</div>
			

      {/* <section>

        <div className="grid guttersx2">
          {props.data.relatedEvents &&
            props.data.relatedEvents.edges.map(({node}, i) => {
              const end = node.end || node.start
              const date = parseInt(moment(end).format('YYYYMMDD'))
              return now <= date ? (
                <EventThumbnail {...node} theme={theme.white} key={`${node.slug}-event-link`} />
              ) : null
            })}
        </div>

        <div className="guttersx2 inner">
          <Link to="/events" className="h2 link">{viewAll}</Link>
        </div>
      </section> */}

    </Layout>
  )
}

EventPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default EventPage

export const pageQuery = graphql`
  query eventQuery($slug: String!, $locale: String!) {
    settings: allContentfulSettings(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          ...Settings
        }
      }
    }
    contentfulEvent(slug: {eq: $slug}, node_locale: {eq: $locale}) {
      title
      slug
      start
      dateLong: start(formatString: "DD MMM YYYY")
      end
      coverImage {
        fluid(maxWidth: 800, quality: 90) {
          srcSet,
          sizes,
          src,
          aspectRatio
        }
      }
      address {
        childMarkdownRemark {
          html
        }
      }
      details {
        childMarkdownRemark {
          html
        }
      }
      bodyCopy {
        childMarkdownRemark {
          html
        }
      }
      linkText
      linkUrl
    }
    relatedEvents: allContentfulEvent(
      filter: { node_locale: { eq: "en-US" } }
      sort: {
        fields: [start]
      }
    ) {
      edges {
        node {
          ...EventLink
        }
      }
    }
  }
`
